import axios from 'axios'

export default {
  getAllQuiz () {
    return axios.get(process.env.VUE_APP_USER_QUIZ)
  },

  getCourseChapterQuiz (courseId, chapterId) {
    return axios.get(process.env.VUE_APP_USER_QUIZ + '/course/' + courseId + '/chapter/' + chapterId)
  },

  createQuiz (data) {
    return axios.post(process.env.VUE_APP_USER_QUIZ, data)
  },

  addQuizQuestion (data) {
    return axios.post(process.env.VUE_APP_QUIZ_QUESTION + '/add', data)
  },

  getQuizQuestions (id) {
    return axios.get(process.env.VUE_APP_QUIZ_QUESTION + '/quiz/' + id)
  },

  updateQuiz (data, id) {
    return axios.put(process.env.VUE_APP_USER_QUIZ + '/' + id, data)
  },

  getQuizDetails (id) {
    return axios.get(process.env.VUE_APP_USER_QUIZ + '/' + id)
  },

  deleteQuiz (id) {
    return axios.delete(process.env.VUE_APP_USER_QUIZ + '/' + id)
  },

  deleteQuestion (id) {
    return axios.delete(process.env.VUE_APP_QUIZ_QUESTION + '/delete/' + id)
  },

  updateQuestion (data, id) {
    return axios.put(process.env.VUE_APP_QUIZ_QUESTION + '/update/' + id, data)
  },

  saveQuestionAttemptAnswer (data) {
    return axios.post(process.env.VUE_APP_QUIZ_ATTEMPT_LOG + '/save-answer', data)
  },

  validateAnswers (data, quizId, profileId) {
    return axios.post(process.env.VUE_APP_QUIZ_ATTEMPT_LOG + '/validate-answer/' + quizId + '/' + profileId, data)
  },

  reattemptQuiz (data) {
    return axios.post(process.env.VUE_APP_QUIZ_ATTEMPT_LOG + '/re-attempt-quiz', data)
  }
}
