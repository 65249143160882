
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { ArrowLeftOutlined, HomeOutlined, PlusOutlined } from '@ant-design/icons-vue'

import AddEditQuestion from '@/components/agency/quiz/AddEditQuestion.vue'
import ListQuizQuestion from '@/components/agency/quiz/ListQuizQuestion.vue'

import quizService from '../../../services/quiz'

export default defineComponent({
  components: {
    ArrowLeftOutlined,
    HomeOutlined,
    PlusOutlined,
    AddEditQuestion,
    ListQuizQuestion
  },
  setup () {
    const route = useRoute()
    const courseId = ref<string>(route.params.courseId as string)
    const chapterId = ref<string>(route.params.chapterId as string)
    const quizId = ref<string>(route.params.quizId as string)
    const quiz = ref<{ _id: string; name: string; description: string; is_published: boolean; start_time: string; end_time: string; no_of_attempt: number; review: boolean; shuffle: boolean; no_of_questions: number; time_limit: number; course: string; chapter: string }>()
    const question = ref<{ _id: string; code: string; course: string; name: string; type: string; options: []; answers: []; question: string; option: string }>()
    const showQuestionModel = ref<number>(0)
    const refreshQuesionListKey = ref<number>(0)
    const showAddQuestionModal = () => {
      showQuestionModel.value++
    }
    const refreshQuestion = async (data) => {
      question.value = data.data
      refreshQuesionListKey.value++
    }
    const getQuizDetails = async () => {
      const responce = await quizService.getQuizDetails(quizId.value)
      quiz.value = responce.data
    }
    onMounted(() => {
      getQuizDetails()
    })
    return {
      courseId,
      chapterId,
      quizId,
      showQuestionModel,
      showAddQuestionModal,
      refreshQuestion,
      quiz,
      refreshQuesionListKey,
      question
    }
  }
})
