
import { createVNode, defineComponent, ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { notification, Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons-vue'

import AddEditQuestion from '@/components/agency/quiz/AddEditQuestion.vue'

import quizService from '../../../services/quiz'

export default defineComponent({
  components: {
    EditOutlined,
    DeleteOutlined,
    AddEditQuestion
  },
  props: ['refreshQuesionListKey', 'question'],
  setup (props) {
    const route = useRoute()
    const activeKey = ref([])
    const pageSize = ref(5)
    const current = ref(1)
    const minValue = ref(0)
    const maxValue = ref(5)
    const showQuestionModel = ref<number>(0)
    const editQuestion = ref<boolean>(false)
    const quizId = ref<string>(route.params.quizId as string)
    const quiz = ref<{ _id: string; name: string; description: string; is_published: boolean; start_time: string; end_time: string; no_of_attempt: number; review: boolean; shuffle: boolean; no_of_questions: number; time_limit: number; course: string; chapter: string }>()
    const questions = ref<Array<{ _id: string; code: string; course: string; name: string; type: string; options: []; answers: []; question: string; option: string }>>([])
    const editQuestionData = ref<{ _id: string; code: string; course: string; name: string; type: string; options: []; answers: []; question: string; option: string; index: number }>()
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const getQuizDetails = async () => {
      const responce = await quizService.getQuizDetails(quizId.value)
      quiz.value = responce.data
      if (quiz.value.no_of_questions > 0) {
        pageSize.value = quiz.value.no_of_questions
        maxValue.value = quiz.value.no_of_questions
      }
    }
    const deleteQuestion = async (question, index) => {
      try {
        await quizService.deleteQuestion(question._id)
        questions.value.splice(index, 1)
        notify('Success', 'Question deleted seccessfully', 'success')
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const refreshQuestion = async (data) => {
      questions.value[data.index] = data.data
    }
    const showDeleteConfirm = async (question, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk () {
          deleteQuestion(question, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const showEditQuestionModal = (question, index) => {
      editQuestionData.value = question
      editQuestionData.value.index = index
      editQuestion.value = true
      showQuestionModel.value++
    }
    const checkOptionCorrect = (question, option) => {
      const index = question.answers.findIndex((n) => n.option === option._id)
      if (index >= 0) {
        return true
      } else {
        return false
      }
    }
    const getQuizQuestions = async () => {
      try {
        const responce = await quizService.getQuizQuestions(quizId.value)
        questions.value = responce.data
        console.log(questions)
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const onShowSizeChange = (current: number, pageSize: number) => { // Page size change
      minValue.value = 0
      maxValue.value = current * pageSize
    }
    watch(pageSize, () => {
      console.log('pageSize', pageSize.value)
    })
    watch(current, () => { // Page change
      if (current.value <= 1) {
        minValue.value = 0
        maxValue.value = quiz.value.no_of_questions - 1
      } else {
        minValue.value = maxValue.value
        maxValue.value = current.value * (quiz.value.no_of_questions)
      }
    })
    onMounted(() => {
      getQuizQuestions()
      getQuizDetails()
    })
    watch(() => props.refreshQuesionListKey, () => {
      questions.value.push(props.question)
    })
    return {
      notify,
      getQuizQuestions,
      activeKey,
      questions,
      pageSize,
      minValue,
      maxValue,
      current,
      onShowSizeChange,
      showDeleteConfirm,
      showQuestionModel,
      editQuestion,
      editQuestionData,
      showEditQuestionModal,
      refreshQuestion,
      getQuizDetails,
      checkOptionCorrect
    }
  }
})
