<template>
  <a-modal title="Add New Question" :visible="visible" @ok="validateQuestionForm" @cancel="handleCancel" width="1000px">
    <div class="add-edit-question">
      <a-form
        ref="questionFormRef"
        :model="questionForm"
        :rules="questionRules"
        layout="vertical"
        :wrapper-col="{ span: 24 }"
        :label-col="{ span: 24 }"
      >
        <a-form-item label="Question" required name="name">
          <Editor
            api-key="yg6ojqa5t76ehq8ftov1i5hwtgz39ef4vvj4yp0scmcp5pxc"
            v-model="questionForm.name"
            :init="{
              height: 300,
              menubar: true,
              plugins: [
                'print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons'
              ],
              toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | addcomment',
              quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
              toolbar_mode: 'sliding',
              tinydrive_token_provider: `${tokenURL}?user_id=${userId}&email=${email}`
            }"
          />
        </a-form-item>
        <div class="options">
          <a-form-item v-for="(answer, index) in questionForm.answers"
            :key="answer.key"
            v-bind="formItemLayout"
            :label="'Answer ' + (index + 1)"
            :name="['answer', index, 'option']"
          >
            <a-input size="large" v-model:value="answer.option" allow-clear autocomplete="off" placeholder="Please input answer" :style="{width: '90%', margin: '0 5px 0 0'}"/>
            <MinusCircleOutlined
              v-if="
                questionForm.answers.length > 1 &&
                questionForm.answers.length - 1 != index
              "
              :disabled="questionForm.answers.length === 1"
              @click="removeAnswer(answer)"
              :style="{ fontSize: '20px', color: '#999999' }"
            />
            <PlusCircleOutlined
              v-if="
                questionForm.answers.length == 1 ||
                questionForm.answers.length - 1 == index
              "
              :disabled="questionForm.answers.length === 1"
              @click="addAnswer"
              :style="{ fontSize: '20px', color: '#72cf97' }"
            />
          </a-form-item>
        </div>
        <div class="option-answer">
          <div class="label">Which answer is true?</div>
          <a-form-item v-for="(answer, index) in questionForm.answers"
            :key="answer.key"
            :name="['answer', index, 'correct']"
          >
            <a-checkbox size="large"  v-model:checked="answer.correct">Answer  {{index + 1}}</a-checkbox>
          </a-form-item>
        </div>
      </a-form>
    </div>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, UnwrapRef, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { notification } from 'ant-design-vue'
import Editor from '@tinymce/tinymce-vue'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons-vue'

import quizService from '../../../services/quiz'
import validate from '../../../services/validator'
interface QuizAnswer {
  _id?: string;
  option: string;
  correct?: boolean;
  key: number;
}
interface QuizFormState {
  _id?: string;
  name: string;
  type: string;
  code: string;
  course: string;
  quiz: string;
  answers: QuizAnswer[];
}

export default defineComponent({
  props: ['showQuestionModel', 'editQuestion', 'editQuestionData'],
  components: {
    Editor,
    MinusCircleOutlined,
    PlusCircleOutlined
  },
  setup (props, { emit }) {
    const route = useRoute()
    const questionFormRef = ref()
    const courseId = ref<string>(route.params.courseId as string)
    const chapterId = ref<string>(route.params.chapterId as string)
    const quizId = ref<string>(route.params.quizId as string)
    const tokenURL = ref<string>(process.env.VUE_APP_API_URL + process.env.VUE_APP_JWT_TOKEN_Generate)
    const profile = JSON.parse(localStorage.profile)
    const userId = ref<string>(profile._id)
    const email = ref<string>(profile.email)
    const questionRules = validate.quizQuestionValidation
    const visible = ref<boolean>(false)
    const formItemLayout = {
      labelCol: {
        xs: { span: 20 },
        sm: { span: 20 }
      },
      wrapperCol: {
        xs: { span: 20 },
        sm: { span: 20 }
      }
    }
    const questionForm: UnwrapRef<QuizFormState> = reactive({
      name: '',
      type: '',
      code: '',
      course: courseId.value,
      quiz: quizId.value,
      answers: [{ option: '', correct: false, key: Date.now() }, { option: '', correct: false, key: Date.now() + 1 }]
    })
    const addAnswer = () => {
      questionForm.answers.push({ option: '', correct: false, key: Date.now() })
    }
    const removeAnswer = (item) => {
      const index = questionForm.answers.indexOf(item)
      if (index !== -1) {
        questionForm.answers.splice(index, 1)
      }
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const createQuestion = async (values: QuizFormState) => {
      try {
        const correctAns = values.answers.filter((obj) => obj.option !== '').length
        if (correctAns !== values.answers.length) {
          notify('Error', 'Please fill all answers', 'error')
          return
        }
        const correctAnsCount = values.answers.filter((obj) => obj.correct === true).length
        if (correctAnsCount === 0) {
          notify('Error', 'Please select atleast one correct answer', 'error')
          return
        } else if (correctAnsCount === 1) {
          values.type = 'Single Choice'
        } else {
          values.type = 'Multi Choice'
        }
        const responce = await quizService.addQuizQuestion(values)
        emit('refreshQuestionList', responce)
        questionForm.name = ''
        questionForm.type = ''
        questionForm.code = ''
        questionForm.course = courseId.value
        questionForm.quiz = quizId.value
        questionForm.answers = [{ option: '', correct: false, key: Date.now() }, { option: '', correct: false, key: Date.now() + 1 }]
        notify('Success', 'Question added successfully', 'success')
        visible.value = false
      } catch (error) {
        console.log(error)
        notify('Error', error.data, 'error')
      }
    }
    const editQuestion = async (values: QuizFormState) => {
      try {
        const correctAns = values.answers.filter((obj) => obj.option !== '').length
        if (correctAns !== values.answers.length) {
          notify('Error', 'Please fill all answers', 'error')
          return
        }
        const correctAnsCount = values.answers.filter((obj) => obj.correct === true).length
        if (correctAnsCount === 0) {
          notify('Error', 'Please select atleast one correct answer', 'error')
          return
        } else if (correctAnsCount === 1) {
          values.type = 'Single Choice'
        } else {
          values.type = 'Multi Choice'
        }
        const responce = await quizService.updateQuestion(values, props.editQuestionData._id)
        emit('refreshQuestionList', { data: responce.data, index: props.editQuestionData.index })
        notify('Success', 'Quiz updated successfully', 'success')
        visible.value = false
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const validateQuestionForm = async () => {
      questionFormRef.value
        .validate()
        .then(() => {
          if (props.editQuestion) {
            editQuestion(questionForm)
          } else {
            createQuestion(questionForm)
          }
        })
        .catch((error: ValidateErrorEntity<QuizFormState>) => {
          console.log('error', error)
        })
    }
    const handleCancel = () => {
      console.log('Clicked cancel button')
      visible.value = false
    }
    watch(() => props.showQuestionModel, () => {
      visible.value = true
      if (props.editQuestion) {
        questionForm.name = props.editQuestionData.name
        questionForm.type = props.editQuestionData.type
        questionForm.answers = []
        for (const option of props.editQuestionData.options) {
          const correctAnsCount = props.editQuestionData.answers.filter((obj) => obj.option === option._id).length
          let correct = false
          if (correctAnsCount > 0) correct = true
          questionForm.answers.push({ _id: option._id, option: option.name, correct, key: option._id })
        }
      }
    })
    return {
      courseId,
      chapterId,
      quizId,
      validateQuestionForm,
      questionFormRef,
      questionRules,
      questionForm,
      visible,
      handleCancel,
      tokenURL,
      userId,
      email,
      addAnswer,
      removeAnswer,
      formItemLayout
    }
  }
})
</script>
<style lang="scss">
.add-edit-question{
  .options {
    display: flex;
    flex-flow: wrap;
    .ant-row {
      width: 50%;
    }
  }
  .option-answer {
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    .label {
      width: 100%;
      font-size: 16px;
      padding: 15px 0;
      text-align: center;
    }
  }
}
</style>
